import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

const HomePage = () => {
  // Animation controls for background bubbles
  const bubblesAnimation = useAnimation();
  
  // Set up bubble animation
  useEffect(() => {
    // Remove the while loop - the animation will repeat infinitely by itself
    bubblesAnimation.start({
      y: [0, -15, 0],
      transition: { 
        duration: 5, 
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "mirror"
      }
    });
    
    // Clean up animation when component unmounts
    return () => bubblesAnimation.stop();
  }, [bubblesAnimation]);
  // Hero section refs for scroll animations
  const [heroRef, heroInView] = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  // Features section refs
  const [featureOneRef, featureOneInView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  
  const [featureTwoRef, featureTwoInView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  
  const [featureThreeRef, featureThreeInView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  
  // Parallax effect
  const parallaxRef = useRef(null);
  
  useEffect(() => {
    const handleScroll = () => {
      if (parallaxRef.current) {
        const scrollY = window.scrollY;
        parallaxRef.current.style.transform = `translateY(${scrollY * 0.4}px)`;
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="overflow-hidden"
    >
      {/* Hero Section */}
      <section
        ref={heroRef}
        className="relative h-screen flex items-center justify-center overflow-hidden bg-gradient-to-b from-blue-50 to-indigo-100 dark:from-neutral-900 dark:to-neutral-800"
      >
        <div className="absolute inset-0 overflow-hidden">
          <motion.div
            ref={parallaxRef}
            className="absolute top-0 left-0 w-full h-full"
            style={{ opacity: 0.4 }}
          >
            <motion.div 
              animate={bubblesAnimation}
              className="absolute top-20 left-10 w-24 h-24 rounded-full bg-blue-400 dark:bg-blue-600 blur-xl"
            ></motion.div>
            <motion.div 
              animate={bubblesAnimation}
              custom={1}
              transition={{ delay: 0.5 }}
              className="absolute top-40 right-20 w-32 h-32 rounded-full bg-purple-400 dark:bg-purple-600 blur-xl"
            ></motion.div>
            <motion.div 
              animate={bubblesAnimation}
              custom={2}
              transition={{ delay: 1 }}
              className="absolute bottom-40 left-1/3 w-40 h-40 rounded-full bg-indigo-400 dark:bg-indigo-600 blur-xl"
            ></motion.div>
          </motion.div>
        </div>
        
        <div className="container mx-auto px-6 z-10 text-center">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={heroInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl mx-auto"
          >
            <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
              Elias Haapaniemi
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-neutral-700 dark:text-neutral-300">
              Henkilökohtainen sivu
            </p>
        
          </motion.div>
        </div>
        <div className="absolute bottom-[25vh] left-1/2 transform -translate-x-1/2 z-10">
          <motion.div
            animate={{ y: [0, 10, 0] }}
            transition={{ repeat: Infinity, duration: 2 }}
            className="bg-white dark:bg-neutral-800 bg-opacity-70 dark:bg-opacity-70 p-2 rounded-full shadow-lg"
          >
            <svg 
              className="w-8 h-8 text-neutral-600 dark:text-neutral-400" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
            </svg>
          </motion.div>
        </div>
      </section>
      
      {/* Feature One */}
      <section
        ref={featureOneRef}
        className="py-24 bg-white dark:bg-neutral-800"
      >
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row items-center">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              animate={featureOneInView ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
              transition={{ duration: 0.8 }}
              className="md:w-1/2 mb-12 md:mb-0"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-neutral-900 dark:text-white">
                GiuliaPassatSoft 
              </h2>
              <p className="text-lg mb-6 text-neutral-700 dark:text-neutral-300">
                 Ohjelma Windows-koneiden asennuksia varten. 
              </p>
              <ul className="space-y-2 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <span>Sulava C#-käyttöliittymä</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <span>Interaktiivinen Powershell implementaatio</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <span>Reaaliaikainen edistymisen seuranta</span>
                </li>
              </ul>
            </motion.div>
            
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={featureOneInView ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="md:w-1/2"
            >
              <motion.div 
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)" 
                }}
                transition={{ type: "spring", stiffness: 300 }}
                className="relative rounded-xl overflow-hidden shadow-2xl transform transition-all duration-300"
              >
                <div className="aspect-video bg-gradient-to-br from-blue-100 to-neutral-300 dark:from-neutral-700 dark:to-neutral-800 rounded-xl p-4 flex items-center justify-center">
                  <img 
                    src={"https://www.elias.wiki/images/asennusohjelma.png"} 
                    className="rounded-lg object-cover w-full h-full transition-all"
                    style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)" }}
                  />
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>
      
      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-blue-600 to-indigo-600 text-white">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Haluatko asentaa koneasennusohjelman?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Paina alla olevasta napista ja sovellus latautuu. Käyttö omalla vastuulla. 
          </p>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link 
              to="/register" 
              className="px-8 py-3 bg-white text-blue-600 font-medium rounded-full hover:bg-blue-50 transition-all shadow-lg hover:shadow-xl inline-block"
            >
              Lataa
            </Link>
          </motion.div>
        </div>
      </section>
      
      {/* Feature Two */}
      <section
        ref={featureTwoRef}
        className="py-24 bg-neutral-50 dark:bg-neutral-900"
      >
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row-reverse items-center">
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={featureTwoInView ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
              transition={{ duration: 0.8 }}
              className="md:w-1/2 mb-12 md:mb-0 md:pl-12"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-neutral-900 dark:text-white">
                AutoKauppa
              </h2>
              <p className="text-lg mb-6 text-neutral-700 dark:text-neutral-300">
                Esimerkkisivu mahdollisesta autokauppa-sivusta. Klikkaamalla saa sivuston auki.
              </p>
              <ul className="space-y-2 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <span>Admin-paneeli</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <span>Dynaamiset animaatiot</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <span>Sulava käyttöliittymä</span>
                </li>
              </ul>
            </motion.div>
            
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              animate={featureTwoInView ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="md:w-1/2"
            >
              <motion.div 
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)" 
                }}
                transition={{ type: "spring", stiffness: 300 }}
                className="relative rounded-xl overflow-hidden shadow-2xl transform transition-all duration-300"
              >
                <div className="aspect-video bg-gradient-to-br from-blue-100 to-neutral-300 dark:from-neutral-700 dark:to-neutral-800 rounded-xl p-4 flex items-center justify-center">
                  <a href="https://elias.wiki/autokauppa" target="_blank" className="w-full h-full block">
                    <img 
                      src={"https://www.elias.wiki/images/autokauppa.png"} 
                      alt="autokauppa" 
                      className="rounded-lg object-cover w-full h-full transition-all"
                      style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)" }}
                    />
                  </a>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Feature Three */}
      <section
        ref={featureThreeRef}
        className="py-24 bg-white dark:bg-neutral-800"
      >
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row items-center">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              animate={featureThreeInView ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
              transition={{ duration: 0.8 }}
              className="md:w-1/2 mb-12 md:mb-0"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-neutral-900 dark:text-white">
                Tämä sivusto
              </h2>
              <p className="text-lg mb-6 text-neutral-700 dark:text-neutral-300">
                Pilvitallennuspalvelu henkilökohtaiseen käyttöön toimivilla salauksilla. 
              </p>
              <ul className="space-y-2 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <span>Salatut tiedostot ja kirjautumistiedot</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <span>Profiilin kustomointi</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <span>Tiedostojen latauslinkkien luominen muille henkilöille</span>
                </li>
              </ul>
            </motion.div>
            
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={featureThreeInView ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="md:w-1/2"
            >
              <motion.div 
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)" 
                }}
                transition={{ type: "spring", stiffness: 300 }}
                className="relative rounded-xl overflow-hidden shadow-2xl transform transition-all duration-300"
              >
                <div className="aspect-video bg-gradient-to-br from-blue-100 to-neutral-300 dark:from-neutral-700 dark:to-neutral-800 rounded-xl p-4 flex items-center justify-center">
                <motion.svg 
                  whileHover={{ scale: 1.1, rotate: -5 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  className="w-32 h-32 text-blue-500 dark:text-blue-400" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </motion.svg>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default HomePage;