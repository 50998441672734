import React from 'react';
import { motion } from 'framer-motion';

const LoadingScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-indigo-100 dark:from-neutral-900 dark:to-neutral-800">
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col items-center"
      >
        <motion.div 
          animate={{ 
            rotate: 360,
            borderColor: ['#3B82F6', '#6366F1', '#8B5CF6', '#3B82F6'] 
          }}
          transition={{ 
            duration: 1.5,
            repeat: Infinity,
            ease: "linear" 
          }}
          className="rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500 dark:border-indigo-400"
        />
        
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="mt-4 text-xl font-semibold text-neutral-800 dark:text-neutral-200"
        >
          Loading...
        </motion.h2>
        
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: "200px" }}
          transition={{ repeat: Infinity, duration: 2, repeatType: "reverse", ease: "easeInOut" }}
          className="h-1 bg-blue-500 dark:bg-indigo-400 mt-4 rounded-full"
        />
      </motion.div>
    </div>
  );
};

export default LoadingScreen;