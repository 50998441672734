// src/services/authService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://elias.wiki/backend/api';

// Configure axios instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});


api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Only handle 401 (Unauthorized) errors
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      // Redirect to login page if not already there
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export const loginUser = async (email, password) => {
  try {
    const response = await api.post('/auth/login.php', { email, password });
    localStorage.setItem('token', response.data.token);
    return response.data.user;
  } catch (error) {
    throw error;
  }
};

export const registerUser = async (name, email, password) => {
  try {
    const response = await api.post('/auth/register.php', { name, email, password });
    localStorage.setItem('token', response.data.token);
    return response.data.user;
  } catch (error) {
    throw error;
  }
};


export const logoutUser = async () => {
  // For demo purposes, simply remove token
  return new Promise((resolve) => {
    setTimeout(() => {
      localStorage.removeItem('token');
      resolve();
    }, 500);
  });
};

export const getCurrentUser = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) return null;
    
    const response = await api.get('/auth/me.php');
    return response.data.user;
  } catch (error) {
    console.error('Error getting current user:', error);
    
    // Only remove token for authentication errors
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      return null;
    }
    
    // For other errors, don't remove the token but return null
    return null;
  }
};
export const updateUserProfile = async (profileData) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Not authenticated');
    
    const response = await api.put('/auth/profile.php', profileData);
    return response.data.user;
  } catch (error) {
    console.error('Failed to update profile:', error);
    throw error;
  }
};

export const resetPassword = async (email) => {
  try {
    const response = await api.post('/auth/reset-password.php', { email });
    return response.data;
  } catch (error) {
    console.error('Failed to request password reset:', error);
    throw error;
  }
};

export const updatePassword = async (currentPassword, newPassword) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Not authenticated');
    
    const response = await api.post('/auth/update-password.php', {
      currentPassword,
      newPassword
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update password:', error);
    throw error;
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await api.post('/auth/verify-email.php', { token });
    return response.data;
  } catch (error) {
    console.error('Failed to verify email:', error);
    throw error;
  }
};