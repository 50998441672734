// AuthContext.js - Authentication context for user management
import React, { createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { 
  loginUser, 
  registerUser, 
  logoutUser, 
  getCurrentUser,
  updateUserProfile,
  resetPassword,
  updatePassword,
  verifyEmail
} from '../services/authService';

// Create context
const AuthContext = createContext(null);

// Auth provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Check if user is logged in on initial load
  useEffect(() => {
    const initAuth = async () => {
      try {
        setLoading(true);
        const userData = await getCurrentUser();
        if (userData) {
          setUser(userData);
        }
      } catch (err) {
        console.error('Authentication initialization error:', err);
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  // Login function
  const login = async (email, password) => {
    try {
      setLoading(true);
      setError(null);
      
      const userData = await loginUser(email, password);
      setUser(userData);
      toast.success('Successfully logged in!');
      return userData;
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Login failed. Please check your credentials.';
      setError(errorMessage);
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Register function
  const register = async (name, email, password) => {
    try {
      setLoading(true);
      setError(null);
      
      const userData = await registerUser(name, email, password);
      setUser(userData);
      toast.success('Registration successful!');
      return userData;
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Registration failed. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Logout function
  const logout = async () => {
    try {
      setLoading(true);
      await logoutUser();
      setUser(null);
      toast.success('Successfully logged out!');
    } catch (err) {
      console.error('Logout error:', err);
      toast.error('Failed to log out. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Update profile function
  const updateProfile = async (profileData) => {
    try {
      setLoading(true);
      const updatedUser = await updateUserProfile(profileData);
      setUser(updatedUser);
      toast.success('Profile updated successfully!');
      return updatedUser;
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to update profile. Please try again.';
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Password reset function
  const requestPasswordReset = async (email) => {
    try {
      setLoading(true);
      await resetPassword(email);
      toast.success('Password reset email sent. Please check your inbox.');
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to send reset email. Please try again.';
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Change password
  const changePassword = async (currentPassword, newPassword) => {
    try {
      setLoading(true);
      await updatePassword(currentPassword, newPassword);
      toast.success('Password updated successfully!');
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to update password. Please try again.';
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Verify email
  const confirmEmail = async (token) => {
    try {
      setLoading(true);
      await verifyEmail(token);
      
      // Update user state to reflect verified email
      if (user) {
        setUser({
          ...user,
          emailVerified: true
        });
      }
      
      toast.success('Email verified successfully!');
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to verify email. Please try again.';
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    loading,
    error,
    login,
    register,
    logout,
    updateProfile,
    requestPasswordReset,
    changePassword,
    confirmEmail
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Custom hook for using the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};