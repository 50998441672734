// src/pages/FilesPage.jsx
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import axios from 'axios'; // Add this import
import { useAuth } from '../contexts/AuthContext';
import LoadingScreen from '../components/LoadingScreen';
import { useNavigate } from 'react-router-dom';

// Placeholder for API service functions
const getFiles = async () => {
  try {
    const apiUrl = 'https://elias.wiki/backend/api/files/list.php';
    const token = localStorage.getItem('token');
    
    console.log('Making API request to:', apiUrl);
    
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server error response:', errorText);
      
      try {
        // Try to parse as JSON
        const errorJson = JSON.parse(errorText);
        throw new Error(errorJson.error || `API call failed with status: ${response.status}`);
      } catch (e) {
        // If not JSON, use text
        throw new Error(`API call failed with status: ${response.status}`);
      }
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching files:', error);
    toast.error('Failed to load files: ' + error.message);
    throw error;
  }
};



const FilesPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');

  // Fetch files on component mount
  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const apiUrl = 'https://elias.wiki/backend/api/files/list.php';
      const token = localStorage.getItem('token');
      
      if (!token) {
        toast.error('Authentication token not found. Please login again.');
        navigate('/login'); // Now navigate is defined
        return;
      }
      
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
      });
      
      // Rest of your code...
    } catch (error) {
      console.error('Error fetching files:', error);
      toast.error('Failed to load files: ' + error.message);
    }
  };
  
  

  // File upload with react-dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileUpload,
    multiple: true,
    maxSize: 50 * 1024 * 1024, // 50MB file size limit
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach(({ file, errors }) => {
        if (errors[0]?.code === 'file-too-large') {
          toast.error(`${file.name} is too large. Max size is 50MB.`);
        } else {
          toast.error(`Error uploading ${file.name}`);
        }
      });
    }
  });

  async function handleFileUpload(acceptedFiles) {
    try {
      setIsUploading(true);
      setUploadProgress(0);
      
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('file', file);  // Must match $_FILES['file'] in PHP
      
      const token = localStorage.getItem('token');
      
      // Using axios for upload with progress
      const response = await axios.post(
        'https://elias.wiki/backend/api/files/upload.php', 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
          withCredentials: true, // Include credentials for CORS
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          }
        }
      );
      
      // Handle response
      if (response.data.success) {
        toast.success('File uploaded successfully');
        fetchFiles(); // Reload the file list
      } else {
        throw new Error(response.data.error || 'Upload failed');
      }
    } catch (error) {
      console.error('Upload error:', error);
      toast.error('Error uploading file: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  }

  const handleDeleteFile = async (fileId) => {
    // Simulate file deletion
    setFiles(files.filter(file => file.id !== fileId));
    toast.success('File deleted successfully');
  };

  const handleShareFile = async (fileId, emails) => {
    // Simulate file sharing
    toast.success('File shared successfully');
    setIsShareModalOpen(false);
  };

  const openShareModal = (file) => {
    setSelectedFile(file);
    setIsShareModalOpen(true);
  };

  const openDetailModal = (file) => {
    setSelectedFile(file);
    setIsDetailModalOpen(true);
  };

  // Filter and sort files
  const filteredFiles = files
    .filter(file => 
      file.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      file.type.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === 'name') {
        return sortOrder === 'asc' 
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (sortBy === 'size') {
        return sortOrder === 'asc' 
          ? a.size - b.size
          : b.size - a.size;
      } else {
        // Default: sort by date
        return sortOrder === 'asc' 
          ? new Date(a.uploadedAt) - new Date(b.uploadedAt)
          : new Date(b.uploadedAt) - new Date(a.uploadedAt);
      }
    });

  // Calculate storage usage
  const totalStorage = 1024 * 1024 * 1024; // 1GB free tier
  const usedStorage = files.reduce((total, file) => total + file.size, 0);
  const usagePercentage = (usedStorage / totalStorage) * 100;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container mx-auto px-6 py-8"
    >
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
        <div>
          <h1 className="text-3xl font-bold mb-2">Your Files</h1>
          <p className="text-neutral-600 dark:text-neutral-400">
            Manage your uploaded files and share them with friends
          </p>
        </div>
        
        <div className="mt-4 md:mt-0 flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
          <div className="flex items-center bg-white dark:bg-neutral-800 rounded-full px-4 py-2 shadow">
            <input
              type="text"
              placeholder="Search files..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="bg-transparent border-none focus:outline-none w-full"
            />
            <svg className="w-5 h-5 text-neutral-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
          
          <div className="flex items-center">
            <select
              value={`${sortBy}-${sortOrder}`}
              onChange={(e) => {
                const [newSortBy, newSortOrder] = e.target.value.split('-');
                setSortBy(newSortBy);
                setSortOrder(newSortOrder);
              }}
              className="bg-white dark:bg-neutral-800 rounded-lg px-3 py-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="date-desc">Newest First</option>
              <option value="date-asc">Oldest First</option>
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="size-desc">Size (Largest)</option>
              <option value="size-asc">Size (Smallest)</option>
            </select>
          </div>
        </div>
      </div>
      
      {/* Storage usage bar */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm text-neutral-600 dark:text-neutral-400">
            Storage used: {(usedStorage / (1024 * 1024)).toFixed(2)} MB of 1 GB
          </span>
          <span className="text-sm font-medium">{usagePercentage.toFixed(1)}%</span>
        </div>
        <div className="w-full bg-neutral-200 dark:bg-neutral-700 rounded-full h-2.5">
          <div 
            className={`h-2.5 rounded-full ${
              usagePercentage > 90 ? 'bg-red-600' : usagePercentage > 70 ? 'bg-yellow-500' : 'bg-blue-600'
            }`} 
            style={{ width: `${usagePercentage}%` }}
          ></div>
        </div>
      </div>
      
      {/* File upload area */}
      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-xl p-8 mb-8 text-center cursor-pointer transition-colors ${
          isDragActive 
            ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20' 
            : 'border-neutral-300 dark:border-neutral-700 hover:border-blue-500 hover:bg-blue-50 dark:hover:bg-blue-900/10'
        }`}
      >
        <input {...getInputProps()} />
        
        {isUploading ? (
          <div className="py-4">
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
            </div>
            <p className="mt-4 text-neutral-600 dark:text-neutral-400">Uploading... {uploadProgress}%</p>
            <div className="w-full bg-neutral-200 dark:bg-neutral-700 rounded-full h-2.5 mt-2">
              <div 
                className="h-2.5 rounded-full bg-blue-600" 
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
          </div>
        ) : isDragActive ? (
          <div>
            <svg className="mx-auto h-12 w-12 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            <p className="mt-2 text-lg font-medium text-blue-600">Drop files to upload</p>
          </div>
        ) : (
          <div>
            <svg className="mx-auto h-12 w-12 text-neutral-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            <p className="mt-2 text-neutral-700 dark:text-neutral-300">Drag and drop files here, or click to select files</p>
            <p className="mt-1 text-sm text-neutral-500">Maximum file size: 50MB</p>
          </div>
        )}
      </div>
      
      {/* File list */}
      {isLoading ? (
        <div className="flex justify-center py-12">
          <LoadingScreen />
        </div>
      ) : filteredFiles.length === 0 ? (
        <div className="text-center py-12 bg-white dark:bg-neutral-800 rounded-xl shadow-md">
          <svg className="mx-auto h-12 w-12 text-neutral-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
          </svg>
          <h3 className="mt-2 text-lg font-medium text-neutral-900 dark:text-neutral-100">No files found</h3>
          <p className="mt-1 text-neutral-500 dark:text-neutral-400">
            {searchQuery ? 'Try a different search term' : 'Upload your first file to get started'}
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {/* File cards would go here */}
          <p className="col-span-full text-center py-12 text-neutral-500 dark:text-neutral-400">
            File list would be displayed here with file cards for each uploaded file.
          </p>
        </div>
      )}
    </motion.div>
  );
};

export default FilesPage;