// src/pages/DashboardPage.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';

const DashboardPage = () => {
  const { user } = useAuth();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container mx-auto px-6 py-8"
    >
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-2">Welcome, {user?.name || 'User'}!</h1>
        <p className="text-neutral-600 dark:text-neutral-400">
          This is your personal dashboard. Manage your files and share with friends.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {/* Quick Access Card */}
        <div className="bg-white dark:bg-neutral-800 rounded-xl shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4">Quick Access</h2>
          <ul className="space-y-2">
            <li>
              <Link to="/files" className="flex items-center text-blue-600 hover:text-blue-500">
                <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                </svg>
                My Files
              </Link>
            </li>
            <li>
              <Link to="/profile" className="flex items-center text-blue-600 hover:text-blue-500">
                <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
                My Profile
              </Link>
            </li>
            <li>
              <a href="#" className="flex items-center text-blue-600 hover:text-blue-500">
                <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                Shared With Me
              </a>
            </li>
          </ul>
        </div>

        {/* Storage Overview Card */}
        <div className="bg-white dark:bg-neutral-800 rounded-xl shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4">Storage Overview</h2>
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm text-neutral-600 dark:text-neutral-400">Storage used: 0 MB of 1 GB</span>
              <span className="text-sm font-medium">0%</span>
            </div>
            <div className="w-full bg-neutral-200 dark:bg-neutral-700 rounded-full h-2.5">
              <div 
                className="h-2.5 rounded-full bg-blue-600" 
                style={{ width: `0%` }}
              ></div>
            </div>
          </div>
          <Link 
            to="/files" 
            className="text-sm text-blue-600 hover:text-blue-500 flex items-center"
          >
            Manage your storage
            <svg className="w-4 h-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </Link>
        </div>

        {/* Activity Card */}
        <div className="bg-white dark:bg-neutral-800 rounded-xl shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4">Recent Activity</h2>
          <p className="text-neutral-600 dark:text-neutral-400 text-sm">
            No recent activity to display.
          </p>
        </div>
      </div>

      {/* Upload Files Section */}
      <div className="bg-white dark:bg-neutral-800 rounded-xl shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Upload Files</h2>
        <div 
          className="border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-colors border-neutral-300 dark:border-neutral-700 hover:border-blue-500 hover:bg-blue-50 dark:hover:bg-blue-900/10"
          onClick={() => {
            // Redirect to files page with upload modal open
            window.location.href = '/files';
          }}
        >
          <svg className="mx-auto h-12 w-12 text-neutral-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
          </svg>
          <p className="mt-2 text-neutral-700 dark:text-neutral-300">
            Drag and drop files here, or click to select files
          </p>
          <Link 
            to="/files"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Go to Files
          </Link>
        </div>
      </div>

      {/* Quick Tips */}
      <div className="bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-xl p-6">
        <h2 className="text-xl font-semibold mb-4 text-blue-800 dark:text-blue-300">Tips for Getting Started</h2>
        <ul className="space-y-2">
          <li className="flex items-start">
            <svg className="w-5 h-5 text-blue-600 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>Upload your first file by visiting the Files page</span>
          </li>
          <li className="flex items-start">
            <svg className="w-5 h-5 text-blue-600 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>Complete your profile information</span>
          </li>
          <li className="flex items-start">
            <svg className="w-5 h-5 text-blue-600 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>Invite friends to share files with you</span>
          </li>
        </ul>
      </div>
    </motion.div>
  );
};

export default DashboardPage;